import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BackToTop from "../global/BackToTop.js";
import References from "../global/references.js";
import Accordions from "./Accordions.js";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";

class ViralSuppressionFootnote extends Component {
  render() {
    return (
      <p
        className={`mb-1 pl-2 ${
          this.props.inAccordion ? "" : "pos-rel-left-neg-6px"
        }`}
      >
        <span className="cont-hang footnote font-light">
          <span className="hang">*</span>
          Viral suppression means the amount of virus in the blood is so low it
          cannot be measured by a test (&lt;200 copies/mL). This is sometimes
          referred to as undetectable. Viral suppression is the ultimate goal of
          HIV care and treatment.<sup>12</sup>
        </span>
      </p>
    );
  }
}

class Content extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
  }
  closeModal = () => {
    this.setState({ showModal: false });
  };
  showModal = () => {
    if ($(window).width() <= 767) {
      this.setState({ showModal: true });
    }
  };
  scrollToSection = (section) => {
    setTimeout(() => {
      let element = $('h2[data-anchor="' + section + '"]');
      if (element.parent("button").hasClass("active")) {
        let position = element.offset().top;
        let offset = $(window).width() <= 574 ? 100 : 30;
        window.scrollTo({ top: position - offset, behavior: "smooth" });
      }
    }, 500);
  };

  render() {
    return (
      <div
        className={`
        ${this.props.show ? "d-block" : "d-none"}
        state-of-hiv
      `}
      >
        <Modal
          centered
          show={this.state.showModal}
          onHide={this.closeModal}
          className="svg-modal"
        >
          <Modal.Body className="px-0">
            <img
              className="d-block d-md-none"
              src={"/annual-hiv-infections-graph-2015-2019-mobile.svg"}
              alt="Graph Showing Annual HIV Infections in the US, 2015-2019"
            />
          </Modal.Body>
        </Modal>
        <Container
          fluid
          className="hero state-of-hiv"
          role="img"
          aria-label="Group of people standing
                      close together"
        />
        <Container>
          <Row>
            <Col xs={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                <h1 className="sub-title">State of the HIV Epidemic</h1>
                <h1 className="title">
                  SUBSTANTIAL PROGRESS <br className="d-block d-md-none" />
                  AND <br className="d-none d-md-block" />
                  THE CHALLENGES THAT REMAIN
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={10} className="m-auto px-0">
              <Container>
                <Row>
                  <Col xs={12} className="p-0 pt-2">
                    <p>
                      The United States has made enormous strides in HIV
                      treatment, care, and prevention since the epidemic began
                      40 years ago.<sup>1</sup> HIV was once the leading cause
                      of death for young people, but because of scientific
                      advances, fewer people are becoming infected with HIV, and
                      those who do are living longer and healthier lives.
                      <sup>2-5</sup> The rate of new HIV infections declined 73%
                      between 1984 and 2019, and the age-adjusted death rate has
                      dropped more than 80% since its peak in 1995.
                      <sup>4,6</sup>
                    </p>
                    <p>
                      In some ways, however, progress has stalled; too many
                      people remain unaware of their HIV status, and the uptake
                      of pre-exposure prophylaxis (PrEP) medicine has been slow.
                      <sup>7,8</sup>
                    </p>
                    <div className="gray-box">
                      <ul>
                        <li>
                          In <strong>2019</strong>, there were approximately{" "}
                          <strong>1.2 million</strong> people living with HIV in
                          the US<sup>9</sup>
                        </li>
                        <li>
                          In <strong>2019</strong>, there were{" "}
                          <strong>34,800 new infections</strong> in the US,
                          which represented an <strong>8% decrease</strong>{" "}
                          since 2015<sup>9</sup>
                        </li>
                        <li>
                          In <strong>2019</strong>, an estimated{" "}
                          <strong>1 in 8</strong> people living with HIV in the
                          US did not know they had it<sup>7</sup>
                        </li>
                        <li>
                          In <strong>2019</strong>, about <strong>66%</strong>{" "}
                          of people living with HIV received some HIV care,{" "}
                          <strong>50%</strong> were retained in care, and{" "}
                          <strong>56%</strong> were virally suppressed
                          <sup>10 </sup>*
                        </li>
                        <li>
                          In <strong>2016</strong>, an estimated{" "}
                          <strong>1.1 million</strong> people in the US were
                          eligible for PrEP medicine based on their risk
                          factors, but only <strong>78,360</strong> people
                          filled prescriptions<sup>8</sup>
                        </li>
                      </ul>
                      <div className="chart-bg">
                        <div onClick={this.showModal} className="cont-chart">
                          <h3>
                            Annual HIV <br className="d-block d-md-none" />
                            Infections in the{" "}
                            <br className="d-block d-md-none" />
                            US, 2015-2019
                          </h3>
                          <img
                            className="d-none d-md-block"
                            src={"/annual-hiv-infections-graph-2015-2019.svg"}
                            alt="Graph Showing Annual HIV Infections in the US, 2015-2019"
                          />
                          <img
                            className="d-block d-md-none p-2 w-100"
                            src={
                              "/annual-hiv-infections-graph-2015-2019-mobile.svg"
                            }
                            alt="Graph Showing Annual HIV Infections in the US, 2015-2019"
                          />
                        </div>
                      </div>
                      <div className="footnote w-75-mobile">
                        Source: CDC. Annual HIV{" "}
                        <br className="d-block d-md-none" />
                        infections in the US, 2015-2019.
                        <br className="d-block d-md-none" />
                        &nbsp;
                        <a
                          target="_blank"
                          href="https://www.cdc.gov/nchhstp/newsroom/2021/2019-national-hiv-surveillance-system-reports.html"
                        >
                          <span className="emphasis">
                            2019 National HIV Surveillance{" "}
                            <br className="d-block d-md-none" />
                            System Reports
                          </span>
                        </a>
                        , 2021.
                      </div>
                    </div>
                    <p>
                      Despite the advancements in HIV prevention and care,
                      <sup>4</sup> the state of the epidemic today reminds us of
                      the role stigma and discrimination play in increasing
                      risk. There are many social, economic, and structural
                      barriers that continue to prevent some people from
                      accessing the care they need.<sup>11</sup>
                    </p>
                    <p>
                      As a result, progress has been unevenly distributed, with
                      already marginalized groups continuing to bear the brunt
                      of the HIV epidemic.<sup>6</sup> These widening
                      disparities can be seen when looking at the data below,
                      which show rates of new infections, treatment, and death
                      among those groups most impacted by HIV.
                    </p>
                    <ViralSuppressionFootnote inAccordoin={false} />
                    <Accordions
                      scrollToSection={this.scrollToSection}
                      ViralSuppressionFootnote={
                        <ViralSuppressionFootnote inAccordion={true} />
                      }
                    />
                    <h3 className="title">Moving Forward</h3>
                    <Container>
                      <Row>
                        <Col xs={12} className="px-0">
                          <p>
                            HIV has gone from a death sentence to a manageable
                            chronic condition with treatment options.
                            <sup>5,43</sup> But, as the data show, access to
                            these prevention and treatment options is not always
                            evenly distributed.<sup>10</sup> Moving forward, we
                            must focus on ways to lessen the burden of HIV on
                            marginalized communities and recognize that doing so
                            will take collective efforts to overcome the
                            societal barriers that prevent too many members of
                            these communities from accessing the care they need.
                          </p>
                        </Col>
                        <Col xs={12} className="px-0">
                          <BackToTop />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <References page={this.props.page} />
      </div>
    );
  }
}

export default Content;
